import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'
import Tools from './ts/Tools'
import Config from './ts/Config'

import "@/ts/SuperExt";
import Global from './ts/Global'
Tools.GetHtmlAsync("GetResourceURL", {}).then(res => {
    Config.ResourceURL = res.data.resource_url;
    const app = createApp(App);
    app.config.globalProperties.$R = (url:string)=>{
        return Global.GetResourceURL(url);
    }
    app.use(ElementPlus)
    app.use(router)
    app.mount('#app')
});

