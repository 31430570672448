// import XY_User from "@/vo/XY_User";
import { reactive } from "vue";
import Tools from "./Tools";
import { ElLoading } from "element-plus";
import Config from "./Config";
// import SYS_Premission from "@/vo/SYS_Premission";
export default class Global {

    /**
     * APP
     */
    public static app: any = null;
    /**
     * 最后的参数
     */
    public static params_data_dic: { [url: string]: any } = {};

    

    /**
     * 权限组
     */
    // public static premission_list: SYS_Premission[] = reactive([]);

    /**
     * 登录数据
     */
    // public static _LoginUserVO: XY_User = reactive({
    //     uid: "",
    //     phone: "",
    //     nick_name: "",
    //     head_pic: "",
    //     state: 0,
    //     is_admin: 0,
    //     last_login_time: "",
    //     create_time: "",
    //     premission_id: 0
    // });

    /**
   * 图表
   */
    public static Echarts: any;

    // public static async GetLoginUserVOAsync(): Promise<XY_User> {
    //     if (Tools.IsNull(this._LoginUserVO.uid)) {
    //         let res = await Tools.GetHtmlAsync("GetLoginUserData", {});
    //         if (Tools.CheckDataIsOK(res, false)) {
    //             for (const key in res.data) {
    //                 if (Object.prototype.hasOwnProperty.call(res.data, key)) {
    //                     const v = res.data[key];
    //                     (this._LoginUserVO as any)[key] = v;
    //                 }
    //             }
    //             Global.ToUpdatePremissionIdentificationAsync();
    //         }
    //     }
    //     else {
    //         await Global.ToUpdatePremissionIdentificationAsync();
    //     }


    //     return this._LoginUserVO;
    // }

    /**
     * 设置loginUserVO
     * @param v 
     */
    // public static async SetLoginUserVOAsync(v: XY_User) {
    //     if (Tools.IsNotNull(v.uid))
    //         await Global.ToUpdatePremissionIdentificationAsync();
    //     this._LoginUserVO = v;
    // }

    /**
     * 当前登录的Token
     */
    public static get LoginToken(): string {
        return localStorage.getItem("login_token") as string;
    }

    /**
     * 当前登录的Token
     */
    public static set LoginToken(v: string) {
        localStorage.setItem("login_token", v);
    }

    /**
     * 更新权限标识
     */
    // private static async ToUpdatePremissionIdentificationAsync() {
    //     if (Global._LoginUserVO) {
    //         if(Tools.IsNull(Global._LoginUserVO.uid))
    //             await Global.GetLoginUserVOAsync();
    //         if (Global._LoginUserVO.premission_id > 0) {
    //             if (Global.premission_list.length == 0) {
    //                 //进行更新操作
    //                 await this.GetMyPremissionIdentificationListAsync();
    //                 return true;
    //             }
    //         }
    //     }
    //     return true;
    // }
    // private static async GetMyPremissionIdentificationListAsync() {
    //     let res = await Tools.GetHtmlAsync(
    //         "GetMyPremissionIdentificationList",
    //         {}
    //     );
    //     if (Tools.CheckDataIsOK(res, false)) {
    //         Tools.SetArray(this.premission_list, res.data);
    //     }
    // }

    /**
     * 获取资源路径
     * @param url 
     * @returns 
     */
    public static GetResourceURL(url: string) {
        if (typeof url != "string")
            return "";
        if (Tools.IsNull(url))
            return url;
        let u = url.toLowerCase();
        if (u.startsWith('http'))
            return url;
        if (u.startsWith("data:image"))
            return url;
        if (u.startsWith("../assets") || u.startsWith("/public_images/") || u.startsWith("public_images/"))
            return url;
        let out_url = `${Config.ResourceURL}/${url}`;
        while (true) {
            let ls = out_url.replaceAll('//', '/');
            if (ls == out_url)
                break;
            out_url = ls;
        }
        out_url = out_url.replaceAll("https:/", "https://");
        out_url = out_url.replaceAll("http:/", "http://");
        return out_url;
    }

    /**
     * 显示选择权限窗口
     * @param callback 选择回调函数
     * @param multiple 是否多选
     */
    public static ShowSelectPremission(default_select_premission_list: number[], callback: (premission_list: number[]) => void, multiple: boolean = false) {

        Global.app && Global.app.ShowSelectPremission(default_select_premission_list, callback, multiple);
    }


    /**
     * 显示loading
     * @param text 
     */
    public static async ShowLoadingAsync(text: string, callBackAsync: () => Promise<void>) {
        let loading: any = null;
        try {
            loading = ElLoading.service({
                lock: true,
                text: text,
                background: 'rgba(0, 0, 0, 0.7)',
            });
            await callBackAsync();
        }
        catch (ex) {

        }
        finally {
            loading.close();
        }
    }

    /**
 * 退出登录
 */
    public static async UnLoginAsync(): Promise<boolean> {
        let res = await Tools.GetHtmlAsync("UnLogin", {});
        if (Tools.CheckDataIsOK(res)) {
            Global.LoginToken = "";
            Tools.GoTo("/Login");
            return true;
        }
        return false;
    }
    /**
     * 等待
     * @param time 毫秒
     * @returns 
     */
    public static async SleepAsync(time: number) {
        return await new Promise(r => {
            try {
                setTimeout(() => {
                    r(null);
                }, time);
            }
            catch (ex) {

            }
        });
    }

    /**
     * 枚举转换成对象数组
     */
    public static EnumToKeysAndValue(E: any): {
        name: string,
        value: number
    }[] {
        let arr = [];
        for (const key in E) {
            if (!isNaN(+key)) {
                arr.push({
                    name: E[key],
                    value: +key
                });
            }
        }
        return arr;
    }
}