<template>
  <SelectPremissionComponents
    v-if="show_select_premission_window"
    :default_select_premission_list="default_select_premission_list"
    :select_premission_callback_fun="select_premission_callback_fun"
    :select_premission_multiple="select_premission_multiple"
  ></SelectPremissionComponents>
  <router-view></router-view>
</template>
<script>
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
import Global from "@/ts/Global";
import { provide, inject } from "vue";
import Tools from "./ts/Tools";
import SelectPremissionComponents from "@/components/SelectPremissionComponents.vue";
export default {
  components: {
    SelectPremissionComponents
  },
  data() {
    return {
      show_select_premission_window: false, //是否显示权限窗口
      select_premission_callback_fun: function (list) {},
      default_select_premission_list: [], //默认选择
      select_premission_multiple: false, //是否支持多选
    };
  },
  created() {
    Global.app = this;
  },
  mounted() {},
  methods: {
    /**
     * 显示选择权限窗口
     */
    ShowSelectPremission(default_select_premission_list, callback, multiple) {
      this.select_premission_callback_fun = callback;
      this.default_select_premission_list = default_select_premission_list;
      this.select_premission_multiple = multiple;
      this.show_select_premission_window = true;
    },
  },
};
</script>
<style lang="scss">
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
#app {
  width: auto;
  color: #333;
  width: 100%;
  height: 100%;
}
.bottom_pagination {
  margin-top: 20px;
}
.tableheight{
    height:570px;
    overflow-y: auto;
}
</style>
