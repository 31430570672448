import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import("@/views/MainView.vue"),
    children: [
      {
        path: '/premission_group',
        name: 'PremissionGroup',
        component: () => import("@/views/PremissionManagement/PremissionGroup.vue"),
      },
      {
        
        path: '/user_management',
        name: 'UserManagement',
        component: () => import("@/views/UserManagement.vue"),
      },
      {
        
        path: '/merchant_management',
        name: 'MerchantManagement',
        component: () => import("@/views/MerchantManagement.vue"),
      },
      {
        
        path: '/system_management',
        name: 'SystemManagement',
        component: () => import("@/views/SystemManagement.vue"),
      },
      {
        
        path: '/on_chain_management',
        name: 'OnChainManagement',
        component: () => import("@/views/OnChainManagement.vue"),
      },
      {
        
        path: '/system_maintenance',
        name: 'SystemMaintenance',
        component: () => import("@/views/SystemMaintenance.vue"),
      },
      {
        
        path: '/customer_service',
        name: 'CustomerService',
        component: () => import("@/views/CustomerService.vue"),
      },
      
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("../views/LoginView.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
